import styledComponentsRhythm from "@ceteio/styled-components-rhythm";

export const rhythm = styledComponentsRhythm({
  baseFontSize: 2, // 1rem. Browser default makes this 16px
  baseLineHeight: 1.25, // unitless line-height, see: https://css-tricks.com/almanac/properties/l/line-height/#comment-1587658
  rhythmHeight: 0.25, // rem units. With browser default, this is 16px * 0.75rem == 12px
  capHeights: {
    AvenirLTRoman: 0.72,
    AvenirLTBold: 0.72,
    AvenirLTLight: 0.72,
    AvenirLTHeavy: 0.72
  }
});

export const theme = {
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
  },
  spacing: {
    xxs: 0.5,
    xs: 1,
    s: 2,
    m: 4,
    l: 6,
    xl: 14
  },
  cardRatio: 0.7,
  font: {
    h1: {
      size: "3",
      family: "Neutrif Pro Bold",
      smallSize: "2"
    },
    h2: {
      size: "2",
      card: "1.5",
      family: "Neutrif Pro Bold",
      smallSize: "1.2",
      smallCard: "1.1",

    },
    h4: {
      size: "1",
      card: "1",
      family: "Neutrif Pro Bold",
      smallSize: "0.8",
      smallCard: "0.7",

    },
    p: {
      size: "0.95",
      smallSize: "0.7",
      card: "0.83",
      family: "Neutrif Pro Light",
      familyBold: "Neutrif Pro Bold",
    },
    caption: {
      size: "0.8",
      family: "Neutrif Pro Light Italic",
      lineHeight: "1.8"
    },
    button: {
      size: "1.1",
      family: "Neutrif Pro Bold",
      lineHeight: "1"
    },
    navLink:{
      size: "1",
      family: "Neutrif Pro Bold",
      lineHeight: "1.8"
    }
  },
  color: {
    first: "#7D49DF",
    primary: "#5B88E7",
    second: "#fd5c4c",
    third: "#22c087",
    fourth: "#ffd41d",
    blue: "#1b91f5",
    backgroundShade: "#f6f5f5",
    tints: {
      half:{
        first: "#7D49DF",
        primary: "#4d80ff",
        second: "#fc8781",
        third: "#22c087",
        fourth: "#ffd41d",
        blue: "#1b91f5"
      },
      quarter:{
        first: "#7D49DF",
        primary: "#4d80ff",
        second: "#ffdddc",
        third: "#22c087",
        fourth: "#ffd41d",
        blue: "#1b91f5"
      }
    }
  }
};

