
import styled from "styled-components/macro";

const Footer = styled.div`
  height: auto;
  margin: 0;
  padding: 0;
  background-color: #1C0D54;
  z-index: 9500;
  position: relative;
  /* margin-top: ${props => props.theme.spacing.s}rem; */

  h2{
    margin-bottom:  ${props => props.theme.spacing.xs}rem;
  }
  a{
    display: block;
    color: white;
    ${props => props.theme.setFontWithRhythm(props.theme.font.p.family, props.theme.font.p.size, props.theme.font.p.lineHeight)}
  }

`;

export default Footer;