import { Link } from 'gatsby'
import React, { useEffect, useRef, useState } from 'react'
import { IconContext } from 'react-icons'
import { FiShoppingCart } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import {
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
} from 'reactstrap'
import Logo from '../assets/images/logo.inline.svg'
import Banner from '../assets/images/website-banner.jpg'
import SubNav from '../components/Elements/SubNav'
import { Box } from 'grommet'
import { Body } from '../components/Styled/Type.js'
import { openCart } from '../State/cart/actions'
import { theme } from '../styles/theme'
import Cart from './Cart'
import MailchimpForm from './MailchimpInput'

// const OfferBar = ({ visible }) => {
//   return visible ? (
//     <div
//       style={{
//         top: 0,
//         left: 0,
//         position: "fixed",
//         display: "flex",
//         height: 100,
//         width: "100%",
//         backgroundColor: "#2C156D",
//         alignSelf: "stretch",
//         zIndex: 99998,
//       }}
//     >
//       <Col md="10" sm="12">
//         <img
//           alt={""}
//           src={Banner}
//           style={{
//             position: "absolute",
//             zIndex: -1,
//             left: 0,
//             top: 0,
//             height: "100%",
//             width: "auto",
//             maxWidth: "100%",
//             left: 0,
//             objectFit: "contain",
//           }}
//         />
//         {/* <Body style={{ fontSize: "14px", position: "absolute", bottom: 0, left: 20 }} color={"white"}>
//             Use discount code: BLACKFRIDAY2020
//           </Body> */}
//       </Col>
//       <Col md="2" className={"d-none d-md-block"}>
//         <MailchimpForm horizontal />
//       </Col>
//     </div>
//   ) : null;
// };

const PostageBar = ({ visible = true }) => {
  return visible ? (
    <Box
      height={'50px'}
      width={'100%'}
      background={theme.color.first}
      alignSelf={'stretch'}
      pad={'small'}
      align={'center'}
      justify={'center'}
      style={{
        top: 0,
        left: 0,
        position: 'fixed',
        zIndex: 99998,
      }}
    >
      <Box align={'center'} fill={true}>
        <Body
          nowidth
          style={{
            maxWidth: '90%',
            fontSize: '0.9rem',
            margin: '0',
            whiteSpace: 'initial',
            minWidth: 0,
            display: 'inline',
            textAlign: 'center',
          }}
          color={'white'}
        >
          Free UK and European delivery on all Jotos
        </Body>
      </Box>
    </Box>
  ) : null
}

const NavBarComponent = props => {
  // const navRef = useRef();
  const lineItems = useSelector(state => state.cart.checkout.lineItems)
  const dispatch = useDispatch()
  const [collapsed, setCollapsed] = useState(false)
  // const [doc, setDoc] = useState(false);
  // const [notFound, setNotFound] = useState(false);
  const [expand, setExpand] = useState(false)
  // const [navHeight, setNavHeight] = useState(0);
  const [discounted, setDiscounted] = useState(false)

  const handleScroll = event => {
    if (window.scrollY > 60) {
      setExpand(true)
      setCollapsed(false)
    } else {
      setExpand(false)
      setCollapsed(false)
    }
  }

  useEffect(() => {
    setDiscounted(false)
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  useEffect(() => {}, [props])

  const handleClick = e => {
    e.preventDefault()
    dispatch(openCart())
  }
  const toggleNavbar = () => setCollapsed(!collapsed)

  return (
    <>
      <Cart />
      <PostageBar />
      {/* <OfferBar visible={discounted} /> */}
      <Navbar
        fixed="top"
        expand="md"
        dark
        className={expand ? 'expand' : ''}
        style={{ top: discounted ? 100 : 50 }}
      >
        <NavbarBrand href="/">
          <Logo style={{ maxWidth: 200 }} />
        </NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} className="mr-2" />
        <Collapse isOpen={collapsed} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <Link to="/" activeClassName="active">
                Joto
              </Link>
            </NavItem>
            <NavItem>
              <Link to="/store" activeClassName="active">
                Accessories
              </Link>
            </NavItem>
            <NavItem>
              <Link to="/discover" activeClassName="active">
                365 Days Of Art
              </Link>
            </NavItem>
            <NavItem>
              <a href={'https://community.joto.rocks'} target={'_blank'}>
                Community
              </a>
            </NavItem>

            <NavItem>
              <Link to="/about" activeClassName="active">
                About
              </Link>
            </NavItem>
            <NavItem>
              <Link onClick={handleClick} to="#">
                <IconContext.Provider value={{ color: 'black', size: 20 }}>
                  <FiShoppingCart style={{ marginRight: 10, marginTop: -3 }} />
                </IconContext.Provider>
                {`${(typeof lineItems !== 'undefined' && lineItems.length) ||
                  0} Items`}
              </Link>
            </NavItem>
          </Nav>
        </Collapse>
        <SubNav />
      </Navbar>
    </>
  )
}

export default NavBarComponent
