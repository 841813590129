import React from "react";
import { Container, Row, Col } from "../components/Styled/Grid";
import Footer from "../components/Styled/Footer";
import { IconContext } from "react-icons";
import { FaTwitter, FaFacebookF, FaInstagram } from "react-icons/fa";
import { Bullet, BodyText } from "../components/Styled/Type";
import MailchimpForm from "./MailchimpInput";
import { Link } from "gatsby";

const FooterComponent = () => {
  return (
    <Footer className="color_section">
      <Container style={{ display: "flex", height: "100%", paddingTop: 50 }}>
        <div style={{ flex: 1, flexDirection: "column", display: "flex" }}>
          <div style={{ flexGrow: 1 }}>
            <Row>
              <Col xs={3} stack>
                <Bullet color={"white"} small>
                  Explore
                </Bullet>
                <Link to="/" activeClassName="active">
                  Joto
                </Link>
                <Link to="/" activeClassName="active">
                  Accessories
                </Link>
                <Link to="/" activeClassName="active">
                  365 Days Of Art
                </Link>
                <a href="https://community.joto.rocks" target={"_blank"} rel="noreferrer">
                  Community
                </a>
              </Col>
              <Col xs={3} stack>
                <Bullet color={"white"} small>
                  Details
                </Bullet>
                <Link to="/warranty" activeClassName="active">
                  Warranty
                </Link>
                <Link to="/disclaimer" activeClassName="active">
                  Disclaimer
                </Link>
                <a
                  href="https://www.iubenda.com/privacy-policy/58991274/full-legal"
                  target={"_blank"} rel="noreferrer"
                >
                  Privacy
                </a>
              </Col>
              <Col md={{ size: 4, offset: 2 }} stack>
                <Bullet color={"white"} small>
                  Keep up to date
                </Bullet>
                <MailchimpForm />
                <IconContext.Provider value={{ color: "white", size: 30 }}>
                  <a
                    className="footer_icon"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://twitter.com/jotorocks?lang=en"
                  >
                    <FaTwitter />
                  </a>
                </IconContext.Provider>
                <IconContext.Provider value={{ color: "white", size: 30 }}>
                  <a
                    className="footer_icon"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.facebook.com/jotorocks/"
                  >
                    <FaFacebookF />
                  </a>
                </IconContext.Provider>
                <IconContext.Provider value={{ color: "white", size: 30 }}>
                  <a
                    className="footer_icon"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/jotorocks/?hl=en"
                  >
                    <FaInstagram />
                  </a>
                </IconContext.Provider>
              </Col>
            </Row>
          </div>
          <div style={{ flexShrink: 1 }}>
            <Row>
              <BodyText
                color={"white"}
                small
              >{`Copyright \xA9 ${new Date().getFullYear()} Those Ltd. -  All Rights Reserved`}</BodyText>
            </Row>
          </div>
        </div>
      </Container>
    </Footer>
  );
};

export default FooterComponent;
