import {
  compose,
  lifecycle
} from "recompose";

import {
  getInitialCheckout,
  fetchShop,
  fetchProducts,
  removeLineItemInCart,
  decrementQuantity,
  incrementQuantity
} from "../State/cart/operations";
import { openCart, closeCart } from "../State/cart/actions";

// import AOS from "aos";
import Cart from "../components/Shopify/Cart";
import { connect } from "react-redux";

const withLifeCycle = lifecycle({
  componentWillMount() {
    // AOS.init();
    this.props.getInitialCheckout();
    this.props.fetchShop();
    this.props.fetchProducts();
  }
});

const mapStateToProps = state => {
  return {
    isOpen: state.cart.isOpen,
    checkout: state.cart.checkout
  };
};

const mapDispatchToProps = {
  getInitialCheckout,
  fetchShop,
  fetchProducts,
  openCart,
  closeCart,
  removeLineItemInCart,
  decrementQuantity,
  incrementQuantity
};

const withCart = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

const enhance = compose(
  withCart,
  withLifeCycle
);

export default enhance(Cart);
