import { createGlobalStyle } from "styled-components";
import breakpoint from "styled-components-breakpoint";

export const GlobalStyle = createGlobalStyle`


textarea:focus, input:focus{
    outline: none;
}

*:focus {
    outline: none;
}

button:focus {outline:0;}

html { font-size: calc(0.9em + 0.3vw) }

body{
  font-family: 'Neutrif Pro Medium' !important;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: env(safe-area-inset); 
}


#root{
  flex: 1;
  display: flex;
  flex-direction: column;
}



a{
  font-size: inherit
  line-height: ${(props) => props.theme.font.p.lineHeight};
  color: ${(props) => props.theme.color.first};
  text-decoration: none;

  &:hover{
    text-decoration: none;

  }

}

.alert{
  position: absolute;
  width: 100%;
  z-index: 10000000;

  p{
    margin: 0;
    padding: 0;
  }
}

.alert-dismissible .close{
  padding: .75rem 1.25rem;
}


.file-input-wrapper {
    overflow: hidden;
    position: relative;
    margin-right: 20px;
  }
  .file-input-wrapper > input[type="file"] {
    font-size: 200px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 100;
  }

.form-group{
  margin-bottom:  ${(props) => props.theme.spacing.s}rem;
}

.block-img, .video-embed{
  margin: ${(props) => props.theme.spacing.m}rem 0;
}

.cls-1 {
  fill: ${(props) => props.theme.color.fourth};
}

.cls-1, .cls-2, .cls-3, .cls-4 {
  fill-rule: evenodd;
}

.cls-2 {
  fill: ${(props) => props.theme.color.third};
}

.cls-3 {
  fill: ${(props) => props.theme.color.second};
}

.cls-4 {
  fill: ${(props) => props.theme.color.first};
}


.section{
  position: relative;
}

.center{
  text-align: center;
}

.color_section .btn {
  background-color: white;
}

.section{
  /* //padding: ${(props) => props.theme.spacing.m}rem 0; */
}

.full{
  display: flex;
  height: auto;
  justify-content: center;
  align-items: center;

  ${breakpoint("md")`
     height: 100%;
  `}
}

img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }



  .error {
    display: block;
    font-size: 15px;
    padding: 10px;
    position: relative;
    min-height: 2.64286em;
    background: #fbefee;
    color: #c23628;
  }
  
  .card {
    //padding: $spacing-s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: ${(props) => props.theme.spacing.m};
  }

  .shop{
    .quote{
      width: 100%;
      background: $second;
      color: White;
    }
  }
    .product-options {
  
     
  
      .color_option {
        background-color: #1b91f5 !important;
        border: 3px solid #FFF;
        border-radius: 18px;
        float: left;
        margin-right: 20px;
        height: 30px;
        width: 30px;
        transition: all 0.5s;
  
        &.blue {
          background: #1b91f5 !important;
          &.active{
            box-shadow: 0 0 0 2px #1b91f5 !important;
          }
          /* //outline: 2px solid ${(props) => props.theme.spacing.m}; */
        }
  
        &.slate {
          background: #666;
          &.active{
            box-shadow: 0 0 0 2px #666;
          }
          /* //outline: 2px solid #333; */
        }
  
        &.black {
          background: #000;
          &.active{
            box-shadow: 0 0 0 2px #000;
          }
          /* //outline: 2px solid #000; */
        }
  
        &.white {
          background: white;
          border: 1px solid #e3e3e3;
          &.active{
            box-shadow: 0 0 0 2px #000;
          }
          /* //outline: 2px solid #000; */
        }
      }
    }
  }
  

  .product-options{
    padding: ${(props) => props.theme.spacing.xs} 5px;
    overflow: hidden;
    margin-bottom: ${(props) => props.theme.spacing.xs}rem;
  }


  .Product__buy, .Product__price{
    margin-top: ${(props) => props.theme.spacing.xs}rem;
  }


.nav-content{

}

.navbar{

  div, a, img, button{
    transition: all 0.3s linear;
  }

  .nav-link{
    float: left;
  }

  background: #fff;
  padding: ${(props) => props.theme.spacing.xs}rem;

  .sub-nav{
    padding: ${(props) => props.theme.spacing.xs}rem;
  }

  .navbar-brand{
    width: 120px;
  }

  .nav-item a, .nav-item .nav-link{
    font-size: ${(props) => props.theme.font.navLink.size}rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: black;

    ${breakpoint("lg")`
        padding-right: 1.5rem;
        padding-left: 1.5rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    `}

    &.active{
      color: ${(props) => props.theme.color.first};
    }

    .expand &{
      font-size: ${(props) => props.theme.font.navLink.size * 0.7}rem;
      height: 35px !important;
    }
  }


  .nav-item button{
    font-size: 0.7rem;
    padding: 0.3rem 1.5rem;
    margin-top: ${(props) => props.theme.spacing.m}rem;
    position: relative;
  }

  &.expand{
    padding: 0 ${(props) => props.theme.spacing.xs}rem;
    height: 45px;
    border-bottom: 1px solid #f7f6f6;

    div, a, img, button{
      transition: all 0.3s linear;
    }

    .navbar-toggler{
      padding:.2rem .75rem;
    }

    .navbar-toggler-icon{
      width: 0.7em;
      height: -0.5em;
    }

    .navbar-brand{
      width: 70px;
      padding: 0;
    }

    .nav-item a, .nav-item .nav-link{
      padding: ${(props) => props.theme.spacing.xs}rem;
      font-size: ${(props) => props.theme.font.navLink.size * 0.7}rem;

    }

    .nav-item button{
      font-size: ${(props) => props.theme.font.navLink.size}rem;

      padding-right: 1.5rem;
      padding-left: 1.5rem;
      padding-top: 0.2rem;
      padding-bottom: 0.1rem;
      top: -2px;
      position: relative;
    }
  }

  .navbar-toggler{
    background-color: ${(props) => props.theme.color.first};
  }

  .navbar-collapse{
    background: white;

    .navbar-nav{

      .nav-item{
        text-align: center;
        padding: ${(props) => props.theme.spacing.xxs}rem;
      }
    }
  }
}


.pointer_row{
  position: relative;
}

.pointer.before {
  transition: all 0.3s linear;
  z-index: 10;
  box-sizing: border-box;
  position: absolute;
  top: -40px;
  display: block;
  height: 20px;
  border: 20px solid transparent;
  border-bottom-color: white;
  pointer-events: none;
  width: 50;

}

.pointer.after {
  transition: all 0.3s linear;
  z-index: 10;
  box-sizing: border-box;
  content: " ";
  position: absolute;
  top: -40px;
  display: block;
  height: 20px;
  border: 20px solid transparent;
  border-bottom-color: white;
  pointer-events: none;
}

.pointer.before {
  left: 0;
  border-right: 20px solid transparent;
  border-left: 0;
  pointer-events: none;
}

.pointer.after {
  right: 0;
  border-left: 20px solid transparent;
  border-right: 0;
  pointer-events: none;
}

.feature_caption{
  padding: ${(props) => props.theme.spacing.s}rem 0 ${(props) =>
  props.theme.spacing.l}rem;
  text-align: center;

  .feature-icon{
    max-width: 60px;
    margin: 0 auto;
  }
}


.nav-tabs{
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
  border: 0;
  border-bottom: 1px solid black;
}

.nav-tabs .nav-link{
  border: 0;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus{
  border: 0;
  border-bottom: 1px solid black;
}

.nav-tabs .nav-item:first-of-type .nav-link{
  padding-left: 0;
}

.nav-link{
  margin-bottom: 0;
  line-height: 1;
  font-size: 0.8rem;
  margin-bottom: ${(props) => props.theme.spacing.s}*0.1rem
  letter-spacing: 0.001rem;

  &.active{
    color:  ${(props) => props.theme.color.first} !important;
  }
  
}

.tab-pane{
  position: absolute;
}

.tab-content{
  margin: $spacing-xs 0;
}

.tab-content>.tab-pane{
  display:block;
  visibility:hidden;
}

.tab-content>.active{
  visibility:visible;
}


  
     
  
  .color_option {
    background-color: ${(props) => props.theme.color.blue};
    border: 3px solid #FFF;
    border-radius: 18px;
    float: left;
    margin-right: 20px;
    height: 30px;
    width: 30px;
    transition: all 0.5s;

    &.blue {
      background:  ${(props) => props.theme.color.blue};;
      &.active{
        box-shadow: 0 0 0 2px  ${(props) => props.theme.color.blue};;
      }
      //outline: 2px solid  ${(props) => props.theme.color.first};;
    }

    &.slate {
      background: #666;
      &.active{
        box-shadow: 0 0 0 2px #666;
      }
      //outline: 2px solid #333;
    }

    &.black {
      background: #000;
      &.active{
        box-shadow: 0 0 0 2px #000;
      }
      //outline: 2px solid #000;
    }

    &.white {
      background: white;
      border: 1px solid #e3e3e3;
      &.active{
        box-shadow: 0 0 0 2px #000;
      }
      //outline: 2px solid #000;
    }
  }

  .footer_icon{
    font-family: 'Neutrif Pro Medium';
    display: inline-block !important;
    margin-right: 20px;
  }


  .footer_link{
    font-family: 'Neutrif Pro Medium';
  }

  .carousel .slide{
    background: none !important;
  }

  .control-dots{
    display: none;
  }

  .fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.5s;
}

.carousel-anchors{
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 100;
}
.carousel-anchor{
  margin-right: 30px;
  display: inline-block;
}

.carousel-anchor.active{
  border-bottom: 1px solid ${(props) => props.theme.color.first};
}

.carousel-anchor:hover{
  color: ${(props) => props.theme.color.first};
}

.carousel-anchor.invert:hover{
  color: white;
}

.carousel-anchor.invert.active{
  border-bottom: 1px solid white;
}

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .fade-out {
	opacity: 0;
	animation-name: fadeOutOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.5s;
}

  @keyframes fadeOutOpacity {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .fade-text{
    -webkit-transition: color 1s; /* For Safari 3.0 to 6.0 */
    transition: color 1s; /* For modern browsers */
  }
  .invert{
    color: white;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  }

  .block-img{
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .blog_content{
    h2, h3, h4{
      margin-top: 80px;
      margin-bottom: 20px;
    }

    iframe{
      margin-top: 40px;
      width: 100%
    }
  }
`;
