
import styled from "styled-components/macro";
import { darken } from "polished";



export const SubNav = styled.div`
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: ${props => props.theme.color.backgroundShade};
  padding-top: ${props => props.theme.$spacing_s};
  margin-bottom: ${props => props.theme.$spacing_m};
  justify-content: center;
  display: flex;
  align-items: center;
  max-height: 70px;
`

export const SubNavItems = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: space-between;

`


export const RightProfile = styled.div`
  justify-content: right;
  display: flex;
  align-items: center;
  h4{
    color: black
  }

  p, h6{
    margin: 0;
    text-align: right;
  }

`

export const UserProfile = styled.div`
  justify-content: right;
  display: flex;
  align-items: right;
  flex-direction: column;
  padding-right: 20px;

  h4{
    color: black;
    display: block;
    width: 100%;
    text-align: right;
    line-height: 1.5;

    .expand & {
      font-size: 0.7rem;
    }
  }

  a{
    display: block;
    width: 100%;
    text-align: right;
    line-height: 1;
    .expand & {
      font-size: 0.7rem;
    }
  }

`



export const LeftNav = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;

  a{
    ${props => props.theme.setFontWithRhythm(props.theme.font.p.familyBold, props.theme.font.p.size, props.theme.font.p.lineHeight)}
    margin-right: ${props => props.theme.spacing.s}rem;

    &:hover,
    &:focus {
        color: ${props => darken(0.5, props.theme.color.first)} !important;
    }
  }

`