import { Box, Grid, ResponsiveContext } from "grommet";
import React from "react";
import styled from "styled-components/macro";
import { device } from "./Breakpoints";
import { Title } from "./Type";

const isMobile = (size) => {
  if (size === "small" || size === "xsmall") {
    return true;
  } else {
    return false;
  }
};
export const ContentRow = ({ col, title, children }) => {
  return (
    <Box
      pad={{ bottom: "large" }}
      fill={"horizontal"}
      width={{ max: "xxlarge" }}
      responsive
    >
      <ResponsiveContext.Consumer>
        {(size) => (
          <Grid
            responsive
            fill={"horizontal"}
            gap={isMobile(size) ? "medium" : "large"}
            columns={
              isMobile(size)
                ? ["full"]
                : col === "3"
                ? ["flex", "flex", "flex"]
                : ["flex", "flex"]
            }
          >
            {children.map((post) => post)}
          </Grid>
        )}
      </ResponsiveContext.Consumer>
    </Box>
  );
};

export const Container = styled.div`
  width: 100%;
  background-color: ${({ color }) => color || "transparent"};
  align-items: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;
  & + ${() => Container} {
    margin-top: 0;
  }

  &.first {
    padding-top: 8vh;
  }

  padding: ${(props) => !props.image && !props.video && !props.fullWidth ? "3em" : "0em"};
  border-bottom: ${(props) => (props.border ? "1px solid #e3e3e3" : null)};

  @media ${device.laptop} {
    padding-bottom: ${(props) => (props.border & props.padded ? "15vh" : null)};
    margin-bottom: ${(props) =>
      props.padded && props.fullWidth ? "3rem" : null};
  }
`;

export const Row = styled.div`
  height:auto;
  /* display: ${(props) => (props.fullWidth ? "flex" : "block")}; */
  width: 100%;
  background-image: ${(props) => `url(${props.backgroundImage})` || "none"};
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  align-items: flex-start;
  flex-wrap: wrap;
  display: flex;
  max-width: ${(props) => (props.fullWidth ? "none" : "1800px")};
  margin-bottom: auto;  bottom: 0;
  flex-direction: ${(props) =>
    props.nostack ? "row" : props.switch ? "column-reverse" : "column"};

  & + ${() => Row} {
    margin-top: ${(props) => (props.fullWidth ? "5vh" : "0vh")};
    @media ${device.laptop} {
        margin-top: 0;
    }
  }

  @media ${device.laptop} {
    display: flex;
    /* flex: 1; */
    flex-direction: row;
    justify-content: space-between;
    background-size: 100% auto;
  }

`;

export const Col = styled.div`
  width: ${(props) => {
    let { size, stack, mobile } = props;
    let properties =
      (stack && "100%") ||
      (size === 12 && "100%") ||
      (size === 10 && "84%") ||
      (size === 9 && "75%") ||
      (size === 8 && "66.6667%") ||
      (size === 6 && "49%") ||
      (size === 4 && "32%") ||
      (size === 3 && "25") ||
      (size === 2 && "16%") ||
      (size === 1 && "8%") ||
      "auto";
    return properties;
  }};
  /* float: left; */
  flex: ${(props) => (!props.size ? "1" : null)};
  float: ${(props) => (props.size ? "left" : "none")};

  /* display: ${(props) => (props.stack ? "flex" : null)}; */
  margin-bottom: ${(props) => (props.stack ? "2em" : null)};

   + ${() => Col} {
    justify-content: space-between;
    justify-content: center;
    align-items: center
   }

  @media ${device.laptop} {
    width: ${({ size }) =>
      (size === 12 && "100%") ||
      (size === 10 && "84%") ||
      (size === 8 && "66.6667%") ||
      (size === 6 && "49%") ||
      (size === 4 && "32%") ||
      (size === 3 && "25") ||
      (size === 2 && "16%") ||
      (size === 1 && "8%") ||
      "auto"};
    }
`;

export const Full = styled.div`
  flex: 1;
  background-color: #e3e3e3;
`;

export const Half = styled.div`
  position: relative;
  background-image: ${(props) => `url(${props.backgroundImage})` || "none"};
  background-repeat: no-repeat;
  background-size: auto 100%;
  width: 100%;
  padding-bottom: ${(props) =>
    props.backgroundImage && !props.nopadding ? "100%" : "0"};
  height: ${(props) =>
    props.backgroundImage && !props.nopadding ? "0" : "auto"};
  align-self: stretch;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  margin-bottom: ${(props) => (props.gutter ? "30px" : 0)};
  flex: ${(props) => (props.fit ? "0" : "1")};;
  display: flex;
  flex-grow: 1;
  & + ${(props) => Half} {
    /* flex: ${(props) => (props.fit ? "0.75" : "1")};; */
  }

  @media ${device.laptop} {
    width: 50%;
    /* height: 100%; */
    /* flex: ${(props) => (props.fit ? "0.48" : "1")}; */

    display: ${(props) => (props.noStretch ? "block" : "flex")};
    background-size: 100% auto;
    padding-bottom: ${(props) =>
      props.backgroundImage && !props.nopadding ? "50%" : "0"};
    margin-bottom: 0;
    & + ${() => Half} {
      margin-bottom: 0vh;
      margin-left: ${(props) => (props.gutter ? "5%" : "0")};
    }
  }
`;

export const Third = styled.div`
  position: relative;
  background-image: ${(props) => `url(${props.backgroundImage})` || "none"};
  background-repeat: no-repeat;
  background-size: auto 100%;
  width: 100%;
  padding-bottom: ${(props) =>
    props.backgroundImage && !props.nopadding ? "100%" : "0"};
  height: ${(props) => (props.backgroundImage ? "0" : "auto")};
  align-self: stretch;
  flex-wrap: wrap;
  margin-bottom: ${(props) => (props.gutter ? "30px" : 0)};

  & + ${() => Half} {
    /* margin-top: -5vh; */
  }

  @media ${device.laptop} {
    flex: ${(props) => (props.gutter ? "0.31" : "0.35")};
    display: ${(props) => (props.noStretch ? "block" : "flex")};
    background-size: 100% auto;
    padding-bottom: ${(props) => (props.backgroundImage ? "50%" : "0")};
    & + ${() => Half} {
      margin-bottom: 0vh;
    }
  }
`;

export const Text = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  padding: ${(props) => (props.padded ? "3em" : "0")};
  max-width: 1800px;

  & ${() => Title} {
    margin-top: 0 !important;
  }
`;

export const Image = styled.div`
  width: 100%;
  height: auto;
  padding-bottom: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: ${(props) => props.left ? `left center` : `center center`};
  background-image: ${(props) => `url(${props.src})`};
  position: relative;
`;

export const ImageContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${({ color }) => color || "transparent"};
  align-items: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;
  position: relative;

  & ${() => Text} {
    max-width: 1800px;
    position: absolute;
    left: 50%;
    top: 10%;
    transform: translate(-50%, -10%);
    display: flex;
    justify-content: left;
    align-items: left;
    padding: ${(props) => (props.window < 1900 ? "3em" : "0")};
    padding-top: ${(props) => (props.white ? "0" : "3em")};
  }
`;
