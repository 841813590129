import styled from "styled-components/macro";
import breakpoint from "styled-components-breakpoint";

export const Container = styled.div`
  justify-content: space-around;
  align-items: stretch;
  position: relative;
  flex-direction: row;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  flex-grow: 1;
  flex: 1;
  width: 100%;
  cursor: ${props => (props.link ? "pointer" : "default")};
  display: flex;
  overflow: hidden;
  &:after {
    content: "";
    display: block;
  }
  ${breakpoint("md")`
      margin-bottom: 0;
  `}
`;

export const ContainerInner = styled.div`
  /* position: absolute; */
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: ${props => (props.jot ? "center" : "none")};
  top: 0;
  flex-grow: 1;
`;

export const Body = styled.div`
  padding: ${props => (props.jot ? "0 10% 10% 10%" : "5% 10%")};
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex: 1;
  text-align: ${props => (props.center ? "center" : "left")};
  height: auto;
`;

export const Title = styled.h2`
  font-size: ${props =>
    props.small ? props.theme.font.h2.smallCard : props.theme.font.h2.card}rem;
  line-height: ${props => props.theme.font.h2.lineHeight};
  margin: 0;
  margin-bottom: 5px;
  text-align: ${props => (props.center ? "center" : "left")};

  p + & {
    margin-bottom: ${props =>
      props.small ? props.theme.spacing.xs : props.theme.spacing.xs}rem;
  }
  border-top: ${props => (props.jot ? "2px solid #efefef;" : "none")};
  padding-top: ${props => (props.jot ? "10%" : 0)};
  color: black;
`;

export const Category = styled.h4`
font-family: "Neutrif Studio";
  font-size: ${props =>
    props.small ? props.theme.font.h4.smallCard : props.theme.font.h4.card}rem;
  line-height: ${props => props.theme.font.h4.lineHeight * 0.8};
  color: ${props => props.theme.color.first};
  /* text-transform: uppercase; */
  font-weight: 800;
  margin-top: 0;
  text-align: ${props => (props.center ? "center" : "left")};
`;

export const Text = styled.p`
  font-size: ${props => props.theme.font.p}rem;
  font-weight: 400;
  font-family: "Neutrif Studio";
  line-height: ${props => props.theme.font.p.lineHeight * 0.9};
  text-align: ${props => (props.center ? "center" : "left")};
  margin-bottom: 10px;
  color: black;
  flex-grow: 1;

`;

export const Image = styled.div`
  align-self: flex-start;
  width: ${props => (props.jot ? "80%" : "100%")};
  background-image: url(${props => props.img});
  background-size: cover;
  background-position: center center;
  height: 0;
  overflow: hidden;
  margin: ${props => (props.jot ? "10%" : 0)};
  padding-bottom: ${props => (props.jot ? "80%" : "60%")};
`;
