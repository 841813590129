import React from "react";
import styled from "styled-components/macro";
import { darken } from "polished";
import { Body } from "../Styled/Card";
import { StyledInput } from "../Styled/Form";
import { BodyText } from "../../components/Styled/Type";

const Component = styled.button`
  background-color: ${props =>
    props.theme.color[props.color] || props.theme.color.first};
  color: white;
  border: none;
  padding: ${props =>
    props.small ? props.theme.spacing.xs / 3 : props.theme.spacing.s / 3}rem;
  cursor: pointer;
  font-family: ${props => props.theme.font.button.family};
  font-weight: 700;
  border-radius: 50px;
  font-size: ${props =>
    props.small
      ? props.theme.font.button.size/1.5
      : props.theme.font.button.size}rem;
  width: 100%;
  text-align: center;
  max-width: 200px;
  float: ${props => props.align || "none"};
  margin-top: ${props =>
    props.small ? props.theme.spacing.xs : props.theme.spacing.xs}rem;
  margin-bottom: ${props =>
    props.small ? props.theme.spacing.xs : props.theme.spacing.xs}rem;

  &:hover,
  &:focus {
    background-color: ${props =>
      darken(0.2, props.theme.color[props.color] || props.theme.color.first)};
  }

  &:disabled {
    background: #bfbfbf;
    cursor: not-allowed;
  }

  ${Body} & {
    margin-bottom: 0;
  }

  ${StyledInput} & + {
    margin-top: 10;
  }
`;

const BuyButton = props => (
  <>
    <Component {...props} />
    {props.disabled && <BodyText center>Sold Out</BodyText>}
  </>
);

export default BuyButton;
