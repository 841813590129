import { Box, Grid, Grommet } from 'grommet'
import React, {useEffect} from 'react'
import { ThemeProvider } from 'styled-components'
import Cart from '../../Containers/Cart'
import Footer from '../../Containers/Footer'
import NavBar from '../../Containers/Navbar'
import { GlobalStyle } from '../../styles/global'
import { rhythm, theme } from '../../styles/theme'
import "bootstrap/dist/css/bootstrap.min.css";
import {Helmet} from "react-helmet";
import { useLocation } from "@reach/router"
import { parse } from "query-string"
import '../layout.css';

const Layout = ({ children }) => {
  let metaTitle = 'Joto test'
  let image = 'http://v2.grommet.io/assets/Wilderpeople_Ricky.jpg'
  let metaDescription = 'this is an og graph test for Joto';

  const location = useLocation()
  const {dynamic} = parse(location.search);

  return (
    <Grommet plain>
      <ThemeProvider theme={Object.assign({}, theme, rhythm.theme)}>
        <GlobalStyle />
        <NavBar />
        <Helmet>
          <title>{metaTitle}</title>
          <meta name="image" content={image} />
          <meta name="description" content={dynamic || metaDescription} />
          <meta property="og:title" content={metaTitle} />
          <meta property="og:type" content={`website`} />
          <meta
            property="og:url"
            content="https://romantic-raman-e56d52.netlify.app"
          />
          <meta property="og:image" content={image} />
          <meta property="og:description" content={dynamic || metaDescription} />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:creator" content="{site.siteMetadata.author}" />
          <meta name="twitter:title" content={metaTitle} />
          <meta name="twitter:image" content={image} />
          <meta name="twitter:description" content={metaDescription} />
        </Helmet>

        <Grid
          rows={['flex', 'auto']}
          margin={{ top: '100px' }}
          style={{ minHeight: '100vh' }}
          fill={false}
          pad={"none"}
        >
          <Box
            align={'center'}
            height={{ min: '75vh' }}
            alignContent={'center'}
            justify={'center'}
          >
            {children}
          </Box>
          <Box>
            <Footer />
          </Box>
        </Grid>
        <Cart />
      </ThemeProvider>
    </Grommet>
  )
}

export default Layout
