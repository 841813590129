import styled from "styled-components/macro";
import { device } from "./Breakpoints";

export const Container = styled.div`
  background-color: white;
`;

export const Title = styled.h1`
 /* color: ${props => props.color || "white"}; */
 font-family: 'Neutrif Studio';
 font-weight: 400;
 font-size: 2.5em;
 line-height: 1;
 margin: 1rem 0 2.5rem;
 color: ${props => props.color || "#222222"};
`;


export const Heading = styled.h1`
 /* color: ${props => props.color || "white"}; */
 font-family: 'Neutrif Studio';
 font-weight: 400;
 font-size: 4em;
 line-height: 1;
 margin: 1rem 0;
 color: ${props => props.color || "#222222"};
`;

export const SectionTitle = styled.h2`
  font-family: "Neutrif Studio";
  font-size: 3;
  font-weight: 400;
  color: ${props => props.color || "#222222"};
  width: 100%;
`;

export const SubTitle = styled.h2`
  font-family: "Neutrif Studio";
  font-size: 2.5rem;
  line-height: 1.1;
  font-weight: 400;
  color: ${props => props.color || "#222222"};
  width: 100%;
  flex: 0 0 auto;
  margin-bottom: ${props => props.margin || "1.7em"};
`;

export const SubSubTitle = styled.h3`
  font-family: "Neutrif Studio";
  font-size: ${props => (props.small ? "1.5rem" : "2rem")};
  line-height: 1.1;
  font-weight: 400;
  color: ${props => props.color || "#222222"};
  width: 100%;
  flex: 0 0 auto;
  margin-bottom: ${props => props.margin || "1.7em"};
`;

export const Body = styled.p`
  line-height: 1.5;
  font-size: ${props => (props.bold ? "1rem" : "0.9rem")};
  font-family:  ${props => (props.bold ? " Neutrif Pro Bold" : "Neutrif Studio")};
  font-weight: ${props => (props.bold ? 800 : 400)};
  color: ${props => props.color || "#222222"};
  flex: 0 0 auto;

  @media ${device.laptop} {
   max-width: ${props => (props.nowidth ? "none" : "400px")};
  }

`;

export const BodyText = styled.p`
  max-width: 600px;
  font-size: ${props => (props.small ? "0.7rem" : "1rem")};
  line-height: 1.5;
  font-weight: ${props => (props.bold ? 600 : 400)};
  color: ${props => props.color || "#222222"};
  flex: 0 0 auto;
  text-align: ${props => (props.center ? "center" : null)};

  button + & {
    position: absolute;
    left: 0;
    right: 0;
    margin-top: -10px;
  }
`;

export const Bullet = styled.h3`
  margin: 0;
  width: 100%;
  font-family: "Neutrif Studio";
  font-size: 1.2rem;
  font-weight: 600;
  color: ${props => props.color || "#222222"};
  width: 100%;
  line-height: 1;
  margin-bottom: ${props => props.margin || "1em"};

  + ${Body} {
    margin-top: 0em;
  }

  span {
    width: 80px;
    display: inline-block;
  }
`;

export const Link = styled.a`
  font-size: ${props => props.small ? "0.7rem" : "1rem"};
  max-width: 600px;
  line-height: 1.3rem;
  font-weight: 400;
  flex: 0 0 auto;
`;

export const Anchor = styled.a`
  font-size: ${props => props.small ? "0.7rem" : "1rem"};
  max-width: 600px;
  line-height: 1.3rem;
  font-weight: 400;
  flex: 0 0 auto;
`;


export const Emphasis = styled.span`
  font-weight: 400;
  color: ${props => props.color || "inherit"};
`;
