import React, { useEffect, useState } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { colors } from "../components/Styled/Colors";
import { Button } from "../components/Styled/Components";
import { StyledInput } from "../components/Styled/Form";

const url =
  "//rocks.us10.list-manage.com/subscribe/post?u=ccf0f1e9806bc6c93272f0d5e&amp;id=af6fe3f9fc";

const CustomForm = ({ onSubmitted, status, message, horizontal, stacked }) => {
  let email;

  const [formStatus, setFormStatus] = useState(status);

  useEffect(() => {
    setFormStatus(status);
  }, [status]);

  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onSubmitted({
      EMAIL: email.value,
    });

  const onChange = () => {
    setFormStatus(null);
  };

  return (
    <div
      style={{
        height: "100%",
        flexDirection: horizontal ? "row" : "column",
        display: "flex",
        alignItems: horizontal ? "center" : "flex-start",
        justifyContent: horizontal ? "center" : "flex-start",
      }}
    >
      <StyledInput
        ref={(node) => (email = node)}
        type="email"
        placeholder="Sign up for exclusive offers"
        onChange={onChange}
        horizontal={horizontal}
        stacked={stacked}
        />
      <div
        style={{
          marginTop: !horizontal ? "1rem" : "none",
          marginBottom: !horizontal ? "3rem" : "none",
          height: horizontal ? "auto" : 60,
          width: stacked ? "100%" : "auto",
          justifyContent: "center"
        }}
      >
        {formStatus === "sending" && (
          <div style={{ color: "white" }}>sending...</div>
        )}
        {formStatus === "error" && (
          <div
            style={{ color: "red" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {formStatus === "success" && (
          <div style={{ color: "white" }}>Subscribed.</div>
        )}

        {formStatus === null && (
          <Button
            stacked={stacked}
            horizontal={horizontal}
            margin={"none"}
            onClick={submit}
            color={colors.purple}
          >
            Sign Up
          </Button>
        )}
      </div>
    </div>
  );
};

const MailchimpForm = ({ horizontal, stacked }) => (
  <MailchimpSubscribe
    url={url}
    render={({ subscribe, status, message }) => (
      <CustomForm
        stacked={stacked}
        horizontal={horizontal}
        onSubmitted={(formData) => subscribe(formData)}
        status={status}
        message={message}
      />
    )}
  />
);

export default MailchimpForm;
