import styled from "styled-components/macro";
import { device } from "./Breakpoints";
import { Text } from "./Grid";

export const Button = styled.div`
  background-color: ${(props) => props.color || "none"};
  border-radius: ${(props) => (props.round ? "50%" : "50px")};
  padding: ${(props) => (props.round ? "0px" : "15px")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  margin-top: ${(props) => props.margin || "1rem"};
  margin-bottom: ${(props) => props.margin || "3rem"};
  margin-right: ${(props) => (props.round || props.horizontal ? "1rem" : "0rem")};
  font-weight: 600;
  cursor: pointer;
  align-self: stretch;
  padding: ${(props) => (props.round ? "2%" : "none")};
  min-width: ${(props) => (props.round ? "0" : "200px")};
  max-height: ${(props) => (props.round ? "none" : "50px")};
  max-width: 350px;
  @media ${device.laptop} {
    max-width: ${props => props.stacked ? "100%" : "300px"};
  }
`;

export const NavLink = styled.a`
  font-family: "Neutrif Pro";
  text-decoration: none;
  margin: 20px 30px;
  font-weight: 600;
  color: black;
`;

export const MenuBar = styled.div`
  position: fixed;
  border-bottom: 1px solid #f3f3f3;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  transition: height 300ms ease-in-out;
  height: ${(props) => props.height};
`;

export const VideoContainer = styled.div`
  overflow: hidden;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;
  
  @media ${device.laptop} {
    margin-top: 100px;
  }

  & ${() => Text} {
    z-index: 1000;
    position: relative;
  }
`;
