export const colors = {
  purple: "#6526D7",
  pink: "#FDCFD1",
  red: "#F95658",
  yellow: "#F5D04C",
  green: "#00D089",
  blue: "#5DDBF8",
  midnight: "#1C0D54",
  pastelBlue: "#A3C5E9",
  pastelGreen:  "#EEF3E9"
};
