import React, {useEffect, useState} from "react";
import Avatar from "react-avatar";
import { Anchor, Body } from "../Styled/Type";
import { Link } from "gatsby";
import {
  SubNav,
  LeftNav,
  SubNavItems,
  RightProfile,
  UserProfile
} from "../Styled/SubNav";
import { useSelector, useDispatch } from "react-redux";
import {cognitoLogout } from "../../State/authentication/operations";
import { Auth } from "aws-amplify";
import { imgixurl } from "../../config";
import { isLoggedIn } from "../../utils/auth";

const SubNavComponent = (props) => {

  const {
    offset,
  } = props;

  const dispatch = useDispatch();
  const firstname = useSelector(state => state.profile.info.firstname);
  const lastname = useSelector(state => state.profile.info.lastname);
  const [identityId, setIdentityId] = useState(false);
  const {
    image,
  } = useSelector((state) => state.profile.info);

  const getCredentials = async () => {
    const credentials = await Auth.currentCredentials();
    return encodeURIComponent(credentials.identityId);
  };

  const logoutUser = () => {
    dispatch(cognitoLogout());
  }

  useEffect(() => {
    getCredentials().then((response) => {
      setIdentityId(response);
    });
  }, []);

  return isLoggedIn() ? (
    <SubNav className={"sub-nav"} top={offset}>
          <SubNavItems>
            <LeftNav>
              {/* <Link to="/create">Create Jot</NavLink> */}
              <Link to="/app/profile">Your Profile</Link>
            </LeftNav>
            <RightProfile>
              <UserProfile>
                <Body>{`${firstname} ${lastname}`}</Body>
                <Anchor href="#" small onClick={logoutUser}>
                  Log Out
                </Anchor>
              </UserProfile>

              <Avatar
                round
                size={45}
                src={`${imgixurl}/protected/${identityId}/${image}`}
                />
            </RightProfile>
          </SubNavItems>
    </SubNav>
  ) : null;
};

export default SubNavComponent;
