import styled from "styled-components/macro";

export const StyledInput = styled.input`
    border-radius: 50px;
    display: block;
    width: 100%;
    height: calc(1.5em + 1.5rem + 2px);
    padding: .375rem ${props => props.prepend ? `${props.prepend}rem` : "1rem"};
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    font-family: "Neutrif Pro Regular";
    font-size: 1rem;
    margin-right: ${props => props.horizontal ? "1rem" : "0rem"};
    /* margin-top: ${props => props.theme.spacing.xs}rem; */
    max-height: 50px;
    max-width: ${props => props.stacked ? "100%" : "350px"};

    &:focus {
        outline: 0;
        box-shadow: 0 0 0 2px ${props=>props.theme.color.first};
        border-color: white;
    }
`

export const InputPrepend = styled.div`
   position: absolute;
   color: black;
   height: 20px;
   padding: 0px 10px;
   font-size: ${props => props.small ? "18px" : "28px"};
   color: #e3e3e3;
   margin-top: ${props => props.small ? "5px" : "-1px"};
`;


export const InputLabel = styled.div`
   color: black;
   padding: 0px 10px 5px 3px;
   font-size: 18px;
`;


export const StyledTextArea = styled.textarea`
    border-radius: 15px;
    display: block;
    width: 100%;
    /* height: calc(1.5em + .75rem + 2px); */
    padding: .75rem .75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    font-family: "Neutrif Pro Regular";
    font-size: 1rem;
    /* margin-top: ${props => props.theme.spacing.xs}rem; */

    &:focus {
  /* outline: 2px ${props=>props.theme.color.first} solid; */
        outline: 0;
        box-shadow: 0 0 0 2px ${props=>props.theme.color.first};
        border-color: white;
    }
`

export const ErrorContainer = styled.div`
    font-size: 12px;
    color: red;
    position: absolute;
    padding: .115rem .75rem;
`