import React, { useEffect, useState } from "react";
// import ReactPixel from "react-facebook-pixel";
import { PulseLoader } from "react-spinners";
import { withHandlers } from "recompose";
import BuyButton from "../Styled/BuyButton";
import * as Cart from "../Styled/Cart";
import Loader from "../Styled/Loader";
import { SubSubTitle } from "../Styled/Type";
import LineItem from "./LineItem";


const LineItems = ({
  lineItems,
  incrementQuantity,
  decrementQuantity,
  removeLineItemInCart,
}) => {
  return lineItems.map((line_item) => {
    return (
      <LineItem
        decrementQuantity={() => decrementQuantity(line_item)}
        incrementQuantity={() => incrementQuantity(line_item)}
        removeLineItemInCart={() => removeLineItemInCart(line_item)}
        key={line_item.id.toString()}
        line_item={line_item}
      />
    );
  });
};

const enhance = withHandlers({
  openCheckout: ({ checkout }) => () => {
    // ReactPixel.track("InitiateCheckout");
    window.open(checkout.webUrl);
  },
});

const CartComponent = ({
  checkout: { lineItems = [], totalPrice = 0.0 } = {},
  closeCart,
  isOpen,
  loading,
  removeLineItemInCart,
  decrementQuantity,
  incrementQuantity,
  openCheckout,
}) => {
  const [discounted, setDiscounted] = useState(false);
  const [vip, setVip] = useState(false);

  useEffect(() => {
    // setDiscounted(true);
    // const cookies = new Cookies();
    // let vip = cookies.get("vip");
    // setVip(vip === "true");
  }, []);


  return (
    <Cart.Container open={isOpen}>
      <Cart.Header>
        <SubSubTitle>Your cart</SubSubTitle>
        <Cart.Close onClick={closeCart}>×</Cart.Close>
      </Cart.Header>
      <Cart.Items>
        <LineItems
          lineItems={lineItems}
          removeLineItemInCart={removeLineItemInCart}
          decrementQuantity={decrementQuantity}
          incrementQuantity={incrementQuantity}
        />
      </Cart.Items>
      {loading && (
        <Loader>
          <PulseLoader
            sizeUnit={"px"}
            size={5}
            color={"#000000"}
            loading={loading}
          />
        </Loader>
      )}
      <Cart.Footer>
        {discounted && (
          <div
            style={{
              backgroundColor: "black",
              height: 100,
              position: "absolute",
              top: "-100px",
              left: 0,
              right: 0,
              padding: "1rem",
            }}
          >
            <p style={{ fontSize: "18px", color: "white" }}>
              {`Use code ${vip ? "VIPMYSTERYGIFT" : "BLACKFRIDAY2020"} on the next screen to receive your
              discount`}
            </p>
          </div>
        )}

        <Cart.Total>Total</Cart.Total>
        <Cart.Pricing>
          <span className="pricing">£ {totalPrice.amount}</span>
        </Cart.Pricing>
        <BuyButton onClick={openCheckout}>Checkout</BuyButton>
      </Cart.Footer>
    </Cart.Container>
  );
};

export default enhance(CartComponent);